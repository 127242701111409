@tailwind base;
@tailwind components;
@tailwind utilities;

.opflow__control{
    @apply block w-full text-sm  form-input leading-5;
}

.opflow__control:focus{
    @apply outline-none;
}

.theme-dark .opflow__control{
    @apply text-gray-300;
}